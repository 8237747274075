import { request } from "@/plugins/axios.js";
import { dotData } from "@/utils/index.js";
import store from '@/store/index.js';
import * as utils from "@/utils/index.js";
import axios from "axios";

const cacheObserverObject = {}

const intersectionObserver = new IntersectionObserver((entries) => {
  // 如果 intersectionRatio 为 0，则目标在视野外，
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      if (entry.target.getAttribute('data-key')) {
        // 已监听
        if (entry.target.getAttribute('data-clear')) {
         return 
        } else {
          // 未监听
          entry.target.setAttribute('data-clear', 1)

          const curOffer = cacheObserverObject[entry.target.getAttribute('data-key')];
          // console.log('curOffer', curOffer);
          try{
            let cachOfferIdList = store.getters.GET_CACHOFFERIDLIST || [];
            // 判断当前camp_id是否已打点
            if(!cachOfferIdList?.includes(curOffer?.camp_id)){
              // 打点
              dotData({
                eventName: 'offer_show',
                eventInfo: JSON.stringify({
                    result: curOffer?.mobile_app_id,  //包名
                    ad_id: curOffer?.camp_id,   //offerId
                    extra: curOffer?.extra_json,
                    cAppkey: utils.getParam('appKey')    //appKey
                })
              });
              // 缓存
              cachOfferIdList.push(curOffer?.camp_id)
              store.dispatch('action_get_cachOfferIdList', {data: cachOfferIdList});
            }
          }catch(e){
            console.log(e,'android not set');
          }

          // 展示上报事件
          // console.log('123',curOffer);
          if (curOffer?.stat_info?.impCallback?.length) {
            let promiseQuery = []
            try{
              curOffer?.stat_info?.impCallback.forEach(item => {
                // promiseQuery.push(request(item, {method: 'get'}))
                // console.log('item',item);

                // promiseQuery.push(request({
                //   url: item,
                //   method: 'get'
                // }))
                promiseQuery.push(axios.get(item));
              })
              Promise.all(promiseQuery)
            }catch(e){
              console.log(e);
            }
          }
        }
      }
    }
    else {
      // console.log(entry, "remove active");
    }
  });
});
export default {
  bind(el, binding) {
    if (binding.value?.key) {
      // cacheObserverObject[offerId] = offer
      cacheObserverObject[binding.value?.item[binding.value?.key]] = binding.value?.item
      el.setAttribute('data-key', binding.value?.item[binding.value?.key])
    }
    el && intersectionObserver.observe(el)
   
  },
  unbind(el) {
    const key = el.getAttribute('data-key')
    if (key) {
      delete cacheObserverObject[key]
    }
    intersectionObserver.unobserve(el)
  }
};
