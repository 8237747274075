import Vue from "vue";
import Vuex from "vuex";
import * as api from "@/api/common/index.js";
import * as utils from '@/utils/index';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: undefined,
    loading: false,
    appData: {
      offer: null,
    },
    configList: null,
    cachOfferIdList: utils.getParam('cachOfferIdList'),  //上报offer_show的offerId列表
    accountInfo: utils.getParam('accountInfo'),  //账号信息
    subList: null,  //子任务列表
    channel: null,   //渠道
    // eslint-disable-next-line no-undef
    cachJumpedList: offerWall.cachJumpedList,  //已点击跳转包名(只有app需要)
    cachRateDialogNum: utils.getParam('cachRateDialogNum'),  //好评弹框出现次数

    // TODO 删除web缓存  || utils.getLocal(name)
    // eslint-disable-next-line no-undef
    todayInstalledNum: offerWall.todayInstalledNum,  //今日已安装应用数量
    // eslint-disable-next-line no-undef
    signInLog: offerWall.signInLog,  //七天签到记录 
    // eslint-disable-next-line no-undef
    curDate: offerWall.curDate,  //当前日期
    timeDiff: null,  //距第二天时间差(毫秒数)  
  },
  getters: {
    GET_USER(state) {
      return state.user;
    },
    GET_LOGING(state) {
      return state.loading;
    },
    GET_APPDATA(state) {
      return state.appData;
    },
    GET_CONFIGLIST(state) {
      return state.configList;
    },
    GET_CACHOFFERIDLIST(state) {
      return state.cachOfferIdList;
    },
    GET_ACCOUNTINFO(state) {
      return state.accountInfo;
    },
    GET_SUBLIST(state) {
      return state.subList;
    },
    GET_CHANNEL(state) {
      return state.channel;
    },
    GET_CACHJUMPEDLIST(state) {
      return state.cachJumpedList;
    },
    GET_CACHRATEDIALOGNUM(state) {
      return state.cachRateDialogNum;
    },
    GET_TODAYINSTALLEDNUM(state){
      return state.todayInstalledNum;
    },
    GET_SIGNINLOG(state) {
      return state.signInLog;
    },
    GET_CURDATE(state) {
      return state.curDate;
    },
    GET_TIMEDIFF(state) {
      return state.timeDiff;
    },
  },
  mutations: {
    change_loading(state, data) {
      state.loading = data;
    },
    update_login(state, data) {
      state.user = data;
    },
    /**
     * @description:
     * @param {*} state
     * @param {*} data
     * @return {*}
     */
    update_offerList(state, { data }) {
      state.appData.offer = data;
    },
    update_configList(state, { data }) {
      state.configList = data;
    },
    update_cachOfferIdList(state, { data }) {
      state.cachOfferIdList = data;
    },
    update_accountInfo(state, { data }) {
      state.accountInfo = data;
    },
    update_subList(state, { data }) {
      state.subList = data;
    },
    update_channel(state, { data }) {
      state.channel = data;
    },
    update_cachJumpedList(state, { data }) {
      state.cachJumpedList = data;
    },
    update_cachRateDialogNum(state, { data }) {
      state.cachRateDialogNum = data;
    },
    update_todayInstalledNum(state, { data }) {
      state.todayInstalledNum = data;
    },
    update_signInLog(state, { data }) {
      state.signInLog = data;
    },
    update_curDate(state, { data }) {
      state.curDate = data;
    },
    update_timeDiff(state, { data }) {
      state.timeDiff = data;
    },
  },
  actions: {
    action_loading({ commit }, data) {
      commit("change_loading", data);
    },
    async action_login({ commit, state }) {
      // 获取谷歌id
      let gi = utils.getParam('gi');
      if(!gi){
        const data = await api.googleIdApi({
          width: window.screen.width + '',
          height: window.screen.height + '',
          plugins: navigator.plugins.length + '',
          svName:'/attr', 
          mdName:'post',
        })
        if(data.code==0){
          gi = data.data;
          // googleId缓存到本地
          localStorage.setItem('googleId', gi);
        }
      }
      // 获取渠道映射
      const res = await api.channelMapApi({
        svName: '/qdmp',
        mdName: 'post'
      })
      if(res.code == 0){
        let channelMap = res.data?.channelMap;
        // console.log('渠道', channelMap);
        let cid;
        if(localStorage.getItem('cid')){
          cid = localStorage.getItem('cid')
        }else{
          // eslint-disable-next-line no-undef
          cid = getQueryParams(window.location.href)?.cid; // 渠道代号
          if(cid){
            localStorage.setItem('cid', cid);
          }
        }
        let channel = channelMap[cid]; //渠道
        commit('update_channel', {data: channel});
      }

      return new Promise((resolve) => {
        commit("change_loading", true);
        api.userLoginApi({
          svName:'/lgin', 
          mdName:'post', 
          gi: gi, 
          qd: '',
          ct: 'US', 
          su: 'GMT+08:00'
        }).then((res) => {
          commit("change_loading", false);
          if (res.code == 0) {
            commit("update_login", res.data);
            resolve(res.data);
          } else {
            resolve(undefined);
          }
        });
      });
    },

    /**
     * @description: 更新offer数据
     * @param {*} store
     * @param {*} query
     * @return {*} void
     */
    async action_get_offerList({ dispatch, commit, state }, { query, queryChild, init }) {
      // commit("change_loading", true);
      const res = await api.offerListApi(query);
      const cacheList = state.appData.offer?.offerList || []
      // if (state.appData.offer) {
      //   if (init) {
      //     commit('update_offerList', {data: undefined})
      //   }
      // }
      // if (queryChild) {
      //   const resChild = await api.offerChildListApi(queryChild)
      //   if (res.code == 0 && resChild.code == 0 ) {
      //       // TODO 对子任务进行合并
      //       console.log(resChild, '合并offer 及offer 子任务')
      //       // res.data.offerList = [...cacheList,  res.data.offerLis];
      //       // commit("update_offerList", { data: res.data });
      //   }
      // } else {
        if (res.code == 0 && res.data) {
          res.data.offerList = [...cacheList,  ...res.data.offerList];

          // web不需要过滤已安装应用
          if(!window.offerWall?.gi){
            commit("update_offerList", { data: res.data });

            // TODO 删除
            // app缓存今日安装应用数量
            // const todayInstalledNum = state.todayInstalledNum || 0;
            // dispatch('action_get_todayInstalledNum', {data: todayInstalledNum+1});
          }else{
            // app过滤
            let list = res.data.offerList;
            let mobileAppIdList = [];  //包名列表
            list?.forEach(item=>{
              mobileAppIdList.push(item.mobile_app_id);
            })
            // console.log('mobileAppIdList123',mobileAppIdList);
            // 已安装的包名列表
            let installedRes;
            try{
              installedRes = window.OfferWall?.checkApkInstall(JSON.stringify(mobileAppIdList))
            }catch(e){
              console.log(e);
            }
            // installedRes = ['com.gma.ball.sort.color.water.puzzle','com.mustardgames.police.bike.stunt.mega.ramp.impossibletracks']
            
            let asyncMethods = []; //保存所有异步操作
            // 过滤已安装应用
            list = list?.filter(item => {
              // 未安装
              const mobile_app_id = item?.mobile_app_id;
              if(!installedRes?.includes(mobile_app_id)) return item;
              // 已安装，需要打点、上报进度
              // eslint-disable-next-line no-undef
              let cachMobileAppIdList = offerWall.cachMobileAppIdList || []; //已打点offer_install包名列表
              let cachJumpedList = state.cachJumpedList || []; //已点击跳转包名
              // 点击跳转去安装的 & 未打点过
              if(cachJumpedList?.includes(mobile_app_id) && !cachMobileAppIdList?.includes(mobile_app_id)){
                // 读缓存中的extra_json
                // eslint-disable-next-line no-undef
                const cachExtra = getClientStorage('cachExtra');
                const curItem = cachExtra?.find(i=>i.camp_id == item.camp_id);
                // 打点
                asyncMethods.push(utils.dotData({
                  eventName: 'offer_install',
                  eventInfo: JSON.stringify({
                    result: mobile_app_id,  //包名
                    ad_id: item?.camp_id,    //offerId
                    extra: curItem?.extra_json,
                    cAppkey: utils.getParam('appKey')    //appKey
                  })
                }));
                // 安装同步
                asyncMethods.push(api.offerInstallApi({
                  svName: '/offsc',
                  mdName: 'post',
                  campId: item?.camp_id,
                  status: 0,
                }))
                cachMobileAppIdList?.push(mobile_app_id);
                // app缓存已安装包名
                utils.appCachInfo('cachMobileAppIdList', cachMobileAppIdList);
                // app缓存今日安装应用数量
                const todayInstalledNum = state.todayInstalledNum || 0;
                dispatch('action_get_todayInstalledNum', {data: todayInstalledNum+1});
              }
            });
            await Promise.all(asyncMethods);

            // 将offerId和对应的extra_json缓存
            const extraList = list.map(item=>{
              return {camp_id: item.camp_id, extra_json: item.extra_json}
            })
            // console.log('extra', extraList);
            // app缓存
            utils.appCachInfo('cachExtra', extraList);

            commit("update_offerList", { data: Object.assign(res.data, {offerList: list}) });
          }
        }
      // }
      commit("change_loading", false);
    },
    action_get_configList({commit, dispatch, state}){
      return new Promise((resolve) => {
        commit("change_loading", true);
        api.gameConfigApi({
          svName:'/hcfg', 
          mdName:'post', 
          // eslint-disable-next-line no-undef
          svsn: utils.getParam('productVersion'), 
          // eslint-disable-next-line no-undef
          qdo: utils.getParam('channel'), 
          bzc: ''
        }).then((res) => {
          // console.log('游戏配置',res);
          commit("change_loading", false);
          if (res.code == 0) {
            commit("update_configList", {data: res.data?.map(item => {
              item.taskExtraInfo = item.taskExtraInfo ?  JSON.parse(item.taskExtraInfo) : {}
              // 好评弹框出现次数第一次缓存
              const isNull = state.cachRateDialogNum==undefined || state.cachRateDialogNum==null;
              // console.log('isnull', isNull);
              if(item.sceneId == '40001' && isNull){
                dispatch('action_get_cachRateDialogNum', { data: item.taskExtraInfo?.reviewControl || 0 });
              }
              return item 
            })});
            resolve(res.data);
          } else {
            resolve(undefined);
          }
        });
      });
    },
    action_get_cachOfferIdList({commit}, {data}){
      commit('update_cachOfferIdList', { data });
      // 缓存
      utils.cachInfo('cachOfferIdList', data);
    },
    action_accountInfo({ commit }, data) {
      commit("update_accountInfo", {data});
      // 缓存
      utils.cachInfo('accountInfo', data);
    },
    action_get_subList({ commit }) {
      return new Promise((resolve) => {
        commit("change_loading", true);
        // 获取子任务列表
        api.offerChildListApi({
          svName: "/offsl",
          mdName: "post",
          offerIdList: []
        }).then((res) => {
          // console.log('res123',res);
          commit("change_loading", false);
          if (res.code == 0) {
            commit("update_subList", {data: res.data});
            resolve(res.data);
          } else {
            resolve(undefined);
          }
        });
      });
    },
    action_get_cachJumpedList({commit}, {data}){
      commit('update_cachJumpedList', { data });
      // app缓存
      utils.appCachInfo('cachJumpedList', data);
    },
    action_get_cachRateDialogNum({commit}, {data}){
      console.log('data', data);
      commit('update_cachRateDialogNum', { data });
      // 缓存
      utils.cachInfo('cachRateDialogNum', data);
    },
    action_get_todayInstalledNum({commit}, {data}){
      console.log('todayInstalledNum',data);
      commit('update_todayInstalledNum', { data });
      // app缓存
      utils.appCachInfo('todayInstalledNum', data);

      // TODO 删除web
      // utils.setLocal('todayInstalledNum', data);
    },
    action_get_signInLog({commit}, {data}){
      console.log('signInLog123123',data);
      commit('update_signInLog', { data });
      // app缓存
      utils.appCachInfo('signInLog', data);

      // TODO 删除web
      // utils.setLocal('signInLog', data);
    },
    action_get_curDate({commit}, {data}){
      console.log('curDate123123',data);
      commit('update_curDate', { data });
      // app缓存
      utils.appCachInfo('curDate', data);

      // TODO 删除web
      // utils.setLocal('curDate', data);
    },
  },
});

export default store;
