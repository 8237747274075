export default{
    'Featured': 'новый',
    'myApp': 'Список',
    'Reward': 'бонус',
    'Clicked': 'Нажмите', 
    'Installed': 'играл',
    'Finish': 'Конец',
    'Play': 'Играть',
    'productDesc': 'Ниже приведен список предложений, на которые вы нажали. Если вам не зачислили деньги в течение указанного времени, свяжитесь с нами в течение <b>30</b> дней для расследования. Подождите <b>14</b> дней, пока рекламодатель обработает ваш отчет. В некоторых случаях это может занять больше времени. Спасибо за сотрудничество!', 
    'symbol': '₽',
    'accountTitle': 'Пожалуйста, введите свой аккаунт', 
    'totalReward': 'Ваша общая награда:',
    'payment': 'Способ оплаты',
    'enterName': 'Пожалуйста, введите Ваше имя',
    'enterAccount': 'Пожалуйста, введите свой аккаунт',
    'enterContactInfo': 'Пожалуйста, введите свой адрес электронной почты',
    'submit': 'Применять',
    'Claim': 'Требовать',  
    'copyLink': 'копировать',  
    'LearnIt': 'Узнать его', 
    'rewardTitle': 'Награды за 7-дневную серию',  
    'rewardTip': 'Бонусы обычно выплачиваются в течение <span style="color:#FF8400">30</span> минут.',
    'Day': 'День',
    'finishAllReward': 'Оставайтесь активными, чтобы выиграть больше призов!',
    'freeClaim': 'Продолжайте в том же духе, получите бесплатно уже сегодня!',
    'Install': 'Установите',
    'installApp': 'Установите <span style="color:#FF8400">{installedNum}/{requiredNum} нового приложения сегодня,</span> чтобы сохранить свою серию!',
    'shareTitle': 'Поделитесь и получите бесплатные деньги',
    'shareText': 'Поделитесь с друзьями: скачайте наше приложение в Google Play!',
    'getRewards': 'Поздравления', 
    'successfulText': 'Получайте последовательные награды успешно! Вы можете вывести свой дополнительный бонус прямо сейчас!',
    'keepActive': 'Будьте активны во время проверки платежа.',
    'Processing': 'Обработка',
    'cashOut': 'Отзывать',
    'withdrawalTask': 'Задача вывода средств: сохраняйте активность, чтобы быстрее получить платеж.',
    'completeEveryTask': 'Выполните все задания, чтобы получить наличные',
    'taskList': 'Список заданий',
    'Play&Earn': 'Играть',
    'rateDialogTitle': 'Насколько вы удовлетворены своим общим опытом на данный момент?',
    'rateOnAScale': 'Пожалуйста, оцените нас по шкале от 1 до 5',
    'notSatisfied': 'Не удовлетворены',
    'verySatisfied': 'Очень доволен',
    'thanksText': 'Спасибо за ваш отзыв, мы придаем большое значение вашему общему опыту!',
    'installAndOpen': 'Установите и играйте 5 минут.',
}