import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import * as utils from '@/utils'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
  if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch(()=>{})
}

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/layout.vue'),
    meta: { flag: true },
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index.vue'),
        meta: { flag: true },
      },
      {
        path: '/stateList',
        name: 'state-list',
        component: () => import('@/views/stateList.vue'),
        meta: { flag: true },
      },
      {
        path: '/reward',
        name: 'reward',
        component: () => import('@/views/reward.vue'),
        meta: { flag: true },
      },
    ]
  },
  {
    path: '/about/:id',
    name: 'about-serve',
    component: () => import('@/views/about.vue'),
    meta: { flag: true },
  },
  {
    path: '/account',
    name: 'account-content',
    component: () => import('@/views/account.vue'),
    meta: { flag: true },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { flag: true }
  },
]
const router = new VueRouter({
  routes
})
const whiteList = ['/404']
router.beforeEach(async (to, from, next) => {
if (!whiteList.includes(to.path)) {
  console.log('store.state.GET_USER',store.getters.GET_USER)
  //  if (!store.getters.GET_USER){
    // if (!userInfo) {
    //   next('/404')
    // } else {
    //   // next();
    //   if(!to.fullPath.includes('cid')){
    //     next({
    //       // path: to.path,
    //       ...to,
    //       query:{
    //         ...to.query,
    //         cid: localStorage.getItem('cid')
    //       }
    //     })
    //   }else{
    //     next()
    //   }
    // }
  // } else  {
    console.log('user存在');
    // next();
    if(!to.fullPath.includes('cid')){
      next({
        // path: to.path,
        ...to,
        query: {
          ...to.query,
          cid: localStorage.getItem('cid')
        }
      })
    }else{
      next();
    }
  // }  
} else {
  next();
}

// console.log('to',to, 'from', from);
})

export default router
