import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './index';
import * as utils from '@/utils/index';
import store from '@/store'

// 登录 之前在路由前置守卫中做登录并缓存国家的操作，但获取语言会在那之前先执行
let userInfo = null;
// app 
if(window.offerWall?.gi){
  // 判断客户端是否登录
  try {
    userInfo = window.OfferWall?.getUserInfo() ? JSON.parse(window.OfferWall?.getUserInfo()) : null;
  }catch(e){
    console.log('android not set!');
  }
  // 新版
  if(userInfo){
    store.commit("update_login", userInfo);
    console.log('i18n country', userInfo?.local);
  }else{
    // 旧版/新版客户端没有返回值时请求登录
    userInfo = await store.dispatch('action_login')  // await可在模块顶层使用
  }
}else{
  // web
  userInfo = await store.dispatch('action_login')
  console.log('i18n web country', userInfo?.ct);
}
console.log('获取userInfo',userInfo)

let country = userInfo?.ct || userInfo?.local;
console.log('locale', utils.getLanguage(country));

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: utils.getLanguage(country),
  messages: messages,
});

export default i18n;