import * as api from "@/api/common/index.js";
import {defaultOfferWall} from '@/config/index';
import store from '@/store';

export const throttle = (func, limit)  => {
    let waiting = false;
    return (...args) => {
      if (!waiting) {
        waiting = true;
        setTimeout(() => {
            func(...args);
          waiting = false;
        }, limit);
      }
    }
}
export const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
        func(...args);
        }, delay);
    };
}
export const currencyTrf = (str) => {
  const obj = {
    "USD": "$",
    "CNY": "¥"
  }
  console.log(obj[str], 'obj[str]')
  return obj[str]
}
export const uaInfo = () => {
  return process.env.NODE_ENV == "development" ? "Mozilla/5.0 (Linux; Android 12; moto g pure Build/S3RHS32.20-42-10-14-2-3; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/112.0.5615.135 Mobile Safari/537.36" : navigator.userAgent
}

// 打点
export const dotData = async({eventName, eventInfo})=>{
  await api.buriedApi({
    svName: '/hhstat',
    mdName: 'post',
    googleId: getParam('gi'),
    eventName: eventName,  //事件名
    eventInfo: eventInfo,  //拓展json
    productVersion: getParam('productVersion')
  }).then(val=>{
    console.log('打点', val);
  })
}

// 获取谷歌id等参数
export const getParam = (name)=>{
  // 判断是否为app
  const isApp = window.offerWall?.gi ? true : false;
  switch(name){
    case 'gi':
      // eslint-disable-next-line no-undef
      return isApp ? offerWall.gi : localStorage.getItem('googleId');
    case 'cachOfferIdList':
      // eslint-disable-next-line no-undef  
      return isApp ? offerWall.cachOfferIdList : getLocal('cachOfferIdList');
    case 'accountInfo':
      // eslint-disable-next-line no-undef
      return isApp ? offerWall.accountInfo : getLocal('accountInfo');
    case 'productVersion':
      // eslint-disable-next-line no-undef
      return isApp ? offerWall.productVersion : '1.0.0';
    case 'appKey':
      // eslint-disable-next-line no-undef
      return isApp ? offerWall.appKey : 'L01EAU7G3J6CMS6OGJSW9XMR';
    case 'channel':
      // eslint-disable-next-line no-undef
      return isApp ? offerWall.channel : store.getters.GET_CHANNEL; 
    case 'cachRateDialogNum':
      // eslint-disable-next-line no-undef
      return isApp ? offerWall.cachRateDialogNum : getLocal('cachRateDialogNum');
  }
}

// 本地缓存方法
export const setLocal = (name, data) => {
  let value = JSON.stringify(data);
  localStorage.setItem(name, value);
}
export const getLocal = (name) => {
  let value = localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : undefined
  return value;
}
export const removeLocal = (name) => {
  if (name) {
    localStorage.removeItem(name);
  } else {
    localStorage.clear()
  }
}

// 上报进度时将offer信息的sub_list设为null
export const deleteSubList = (offer)=>{
  const offerCopy = JSON.parse(JSON.stringify(offer));
  offerCopy.sub_list = null;
  return JSON.stringify(offerCopy);
}

// 缓存
export const cachInfo = (name, data)=>{
  // app缓存
  if(window.offerWall?.gi){
    appCachInfo(name, data);
  }else{
    // web缓存
    setLocal(name, data);
  }
}

// app缓存
export const appCachInfo = (name, data)=>{
  try{
    window.OfferWall?.putString(name, JSON.stringify(data));
  }catch(e){
    console.log('android not set', e);
  }
}

// 点击offer时同步进度并将offer加到my app中
export const clickOffer = async(offerId, offer)=>{
  // 同步进度
  const hasId = store.getters.GET_CONFIGLIST.some(item => offerId == item.taskId);
  if(!hasId){
    console.log('同步进度');
    store.commit("change_loading", true);
    const res = await api.syncProgress({
      svName: '/tbjd',
      mdName: 'post',
      sceneId: '10001',
      taskId: offerId,
      progressValue: 0,
      taskExtraInfo: deleteSubList(offer)
    })
    store.commit("change_loading", false);
    console.log('同步进度后', res);

    // 把当前点击offer加到store的configList中
    let list = store.getters.GET_CONFIGLIST;
    list.push({
      progressValue: 0,
      sceneId: '10001',
      taskExtraInfo: offer,
      taskId: offerId
    })
    store.commit('update_configList', {data: list});
    console.log('new',store.getters.GET_CONFIGLIST);
  }
}

// 获取token信息
export const getToken = ()=>{
  // app
  if(window.offerWall?.gi){
    let newAppToken = null;
    try {
      newAppToken = window.OfferWall?.getLoginToken(); //客户端登录返回token
    }catch(e){
      console.log('android not set!');
    }
    return newAppToken || store.getters.GET_USER?.at;
  }else{
    // H5
    return store.getters.GET_USER?.at;
  }
}

// 语言
export const getLanguage = (country)=>{
  const languageMap = {
    'US': 'en',
    'BR': 'br',
    'RU': 'ru'
  }
  if(country && languageMap[country]){
    return languageMap[country];
  }else{
    return 'en';
  }
}

// 获取不同国家支付方式图片
export const getImage = ()=>{
  const imageMap = {
    'US': require('@/assets/ui_paypal.png'),
    'BR': require('@/assets/ui_card_pagbank.png'),
    'RU': require('@/assets/ui_card_qiwi.png')
  }
  const defaultImg = require('@/assets/ui_paypal.png')
  const country = store.getters.GET_USER?.ct || store.getters.GET_USER?.local;
  console.log('country123',imageMap[country]);
  return country&&imageMap[country] ? imageMap[country] : defaultImg;
}