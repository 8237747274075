import { request } from "@/plugins/axios.js"
import store from '@/store'
import { getParam, getToken } from "@/utils";

/** h5归因信息获取--googleId */
export function googleIdApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
  })
}
/** 用户登录列表 */
export function userLoginApi(data) {
  const channel = getParam('channel');
  const appKey = getParam('appKey');
  // console.log('登录channel',channel,'appkey',appKey);
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'Channel-Code': channel +'___' + appKey
    }
  })
}
/** 数据打点 */
export function buriedApi(data) {
  const channel = getParam('channel');
  const appKey = getParam('appKey');
  return request({
    url: process.env.NODE_ENV=='development' ? '/v1/wall': 'https://st.hifreecash.com/v1/wall',
    method: "post",
    data,
    headers:{
      'Channel': channel +'___' + appKey
    }
  })
}
/** offer安装列表 */
export function menusListApi(data) {
  return request({
    url: "//v1/wall",
    method: "post",
    data
  })
}
/** 游戏配置列表 */
export function gameConfigApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': getToken()
    }
  })
}
/** offer安装列表 */
export function offerInsListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data
  })
}
/** offer子任务列表 */
export function offerChildListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': getToken()
    }
  })
}
/** offer列表 */
export function offerListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': getToken()
    }
  })
}

/** 提现列表 */
export function cashListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data
  })
}
/** 提现申请 */
export function cashApplyApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': getToken()
    }
  })
}
/** h5请求同步进度数据 */
export function syncProgress(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': getToken()
    }
  })
}
/** h5渠道信息映射 */
export function channelMapApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
  })
}
/** offer安装同步 */
export function offerInstallApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': getToken()
    }
  })
}