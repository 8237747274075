export default{
    'Featured': 'novo',
    'myApp': 'Lista',
    'Reward': 'bônus',
    'Clicked': 'Clique', 
    'Installed': 'Instalado',
    'Finish': 'Terminar',
    'Play': 'Jogar',
    'productDesc': 'Abaixo está uma lista das ofertas em que você clicou. Se você não for creditado dentro do prazo especificado, entre em contato conosco dentro de <b>30</b> dias para investigar. Aguarde <b>14</b> dias para que o anúncio processe seu relatório. Em alguns casos, pode demorar mais. Obrigado pela sua cooperação!', 
    'symbol': 'R$',
    'accountTitle': 'Por favor insira sua conta', 
    'totalReward': 'Sua recompensa total:',
    'payment': 'Forma de pagamento',
    'enterName': 'Por favor, insira seu nome',
    'enterAccount': 'Por favor insira sua conta',
    'enterContactInfo': 'Por favor insira suas informações de contato',
    'submit': 'Aplicar',
    'Claim': 'Alegar',  
    'copyLink': 'cópia de',  
    'LearnIt': 'Aprenda',    
    'rewardTitle': 'Recompensas consecutivas de 7 dias',  
    'rewardTip': 'Os bônus geralmente são pagos em <span style="color:#FF8400">30</span> minutos',
    'Day': 'Dia',
    'finishAllReward': 'Mantenha-se ativo para ganhar mais prêmios!',
    'freeClaim': 'Mantenha a sequência, reivindique gratuitamente hoje!',
    'installApp': 'Instale <span style="color:#FF8400">{installedNum}/{requiredNum} novo APP hoje para</span> manter sua seqüência!',
    'shareTitle': 'Compartilhe e ganhe dinheiro grátis',
    'shareText': 'Compartilhe com seus amigos: Baixe nosso aplicativo no Google Play!',
    'getRewards': 'Parabéns', 
    'successfulText': 'Obtenha recompensas consecutivas com sucesso! Você pode sacar seu bônus extra agora!',
    'keepActive': 'Mantenha-se ativo durante a revisão do pagamento.',
    'Processing': 'o trato',
    'cashOut': 'Retirar',
    'withdrawalTask': 'Tarefa de retirada: mantenha-se ativo para receber o pagamento mais rapidamente.',
    'completeEveryTask': 'Conclua todas as tarefas para obter saques em dinheiro',
    'taskList': 'Lista de tarefas',
    'Play&Earn': 'Jogue e ganhe',
    'rateDialogTitle': 'Quão satisfeito você está com sua experiência geral até agora?',
    'rateOnAScale': 'Por favor, avalie-nos em uma escala de 1 a 5',
    'notSatisfied': 'Não satisfeito',
    'verySatisfied': 'Muito satisfeito',
    'thanksText': 'Obrigado pelo seu feedback, damos grande importância à sua experiência geral!',
    'installAndOpen': 'Instale e jogue por 5 minutos',
}