export default {
    'Featured': 'Featured', //主页
    'myApp': 'My APP',  //My App
    'Reward': 'Reward',  //奖励页
    'Clicked': 'Clicked',  //offer角标状态
    'Installed': 'Installed', //offer角标状态
    'Finish': 'Finish',  //offer角标状态
    'Play': 'Play',  //按钮文案
    'productDesc': 'Below is a list of the offers you clicked on. If you are not credited within the specified time, contact us within <b>30</b> days to investigate. Please allow <b>2</b> weeks for the advertise to process your report. In some cases, it may take longer. Thanks for your cooperation!', //产品描述
    'symbol': '$', //货币符号
    'accountTitle': 'Please enter your account', 
    'totalReward': 'Your total reward:',   
    'payment': 'Way your payment',     
    'enterName': 'Please enter your name',
    'enterAccount': 'Please enter your account',
    'enterContactInfo': 'Please enter your contact information',
    'submit': 'Submit', //提现按钮文案
    'Claim': 'Claim',  //签到页提现按钮文案
    'copyLink': 'copy link',  //复制链接按钮
    'LearnIt': 'Learn it',    //跳转链接按钮 
    'rewardTitle': '7 Days Streak Rewards',  //签到页标题 
    'rewardTip': 'Bonuses are usually paid within <span style="color:#FF8400">30</span> minutes', //签到页小标题
    'Day': 'Day',
    'finishAllReward': 'Congratulations on completing the 7-day streak rewards, stay active to get more prizes!', //全部完成
    'freeClaim': 'Keep streak, claim for free today!',  //无条件领取
    'installApp': 'Install <span style="color:#FF8400">{installedNum}/{requiredNum} new APP today</span> to keep your streak!', 
    'shareTitle': 'Share and get free cash',  //签到页分享标题
    'shareText': 'Share with your friends: Download our app on Google Play!',  //复制链接文案
    'getRewards': 'Get streak rewards',  // 签到弹框标题
    'successfulText': 'Successfully get streak rewards! You can cash out your extra bonus now!', // 签到弹框中间文案
    'keepActive': 'Keep active during payment review.',  // 提现成功文案
    'Processing': 'Processing',
    'cashOut': 'Cash out',
    'withdrawalTask': 'Withdrawal task: Keep active during payment review to complete it faster.', // 详情弹框标题
    'completeEveryTask': 'Complete every task to get cash withdrawals',
    'taskList': 'Task List',
    'Play&Earn': 'Play & Earn',
    'rateDialogTitle': 'How satisfied are you with your overall experience so far?',
    'rateOnAScale': 'Please rate us on a scale from 1 to 5',
    'notSatisfied': 'Not satisfied',
    'verySatisfied': 'Very satisfied',
    'thanksText': 'Thank you for your feedback, we attach great importance to your overall experience!',
    'installAndOpen': 'Install and open for 5 minutes',
}