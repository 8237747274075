<template>
  <div id="app">
    <router-view />
    <!-- <Loading v-if="vloading"></Loading> -->
    <!-- <Dialog v-model="vshow" @change="onClose"></Dialog> -->
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
// import Dialog from "@/components/dialog.vue";

export default {
  data() {
    return {
      vshow: true,
      timeDiff: null,  //距离第二天时间差
    };
  },
  components: {
    // Loading,
    // Dialog,
  },
  created() {
    this.$nextTick(() => {
    });

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log('刷新');
        window.location.reload();
      } else {
        console.log();
      }
    });
  },
  methods: {
    onClose(e) {
      console.log(e, this.vshow);
    },
    onClick() {},
    setData() {
    },
    // changeSignIn(){
    //   const curTime = new Date();
    //   const cashDate = this.$store.getters.GET_CURDATE;
    //   if(cashDate){
    //     // 判断是否为同一天，避免当前时间还未到零点
    //     // const isSameDay = curTime.getFullYear()==cashDate[0] && curTime.getMonth()+1==cashDate[1] && curTime.getDate()==cashDate[2];
    //     // console.log('同一天',isSameDay);
    //     // if(!isSameDay){
    //       console.log('日期变了');
    //       // 更新当前日期缓存
    //       const newDate = [curTime.getFullYear(), curTime.getMonth() + 1, curTime.getDate()]
    //       this.$store.dispatch('action_get_curDate', {data: newDate});
    //       // 清空当日已安装应用数量的缓存
    //       this.$store.dispatch('action_get_todayInstalledNum', {data: 0});
    //       // 更新签到记录当前第几天
    //       const data = this.$store.getters.GET_SIGNINLOG
    //       if(data && data?.curDay!=7){
    //         const diffDays = curTime.getDate() - cashDate[2];  //天数差值
    //         data.curDay = (data.curDay+diffDays <= 7) ? data.curDay+diffDays : 7;
    //         console.log('curDay改变了');
    //         this.$store.dispatch('action_get_signInLog',{data});
    //       }
    //     // }
    //   }
    // }
  },
  computed: {
    vloading() {
      return this.$store.getters.GET_LOGING;
    },
    trMoney() {
      return this.money.toLocaleString();
    },
    user(){
      return this.$store.getters.GET_USER;
    }
  },
  // watch:{
  //   user:{
  //     handler(val){
  //       // TODO window.offerWall?.gi && 
  //       if(window.offerWall?.gi && val){
  //         const newDate = new Date();
  //         newDate.setDate(newDate.getDate() + 1);
  //         newDate.setHours(0, 0, 0, 0);
  //         // 服务器时间  val?.now:客户端登录返回信息  val?.no:后端返回信息
  //         const loginTime = val?.now || val?.no;  
  //         console.log('登录时间',loginTime);
  //         this.timeDiff = new Date(Date.parse(newDate)).getTime() - new Date(Date.parse(loginTime)).getTime(); //毫秒

  //         this.timer = setInterval(()=>{
  //           // 缓存 距第二天时间（毫秒数）
  //           this.$store.commit('update_timeDiff', {data: this.timeDiff});
  //           this.timeDiff = this.timeDiff-1000;
  //           console.log('-1000',this.timeDiff);
            
  //           // 页面内剩余时间小于等于0
  //           if(this.timeDiff<=0){
  //             // 修改签到相关缓存 
  //             this.changeSignIn();
  //             // 关闭原先定时器
  //             clearInterval(this.timer);

  //             // 重新计时
  //             this.timeDiff = 86400000;  //24小时（毫秒数）
  //             this.timer = setInterval(()=>{
  //               console.log('重新计时');
  //               this.$store.commit('update_timeDiff', {data: this.timeDiff});
  //               this.timeDiff = this.timeDiff-1000;
  //               console.log('-1000',this.timeDiff);
  //             }, 1000)
  //           }
  //         }, 1000)
  //       }
  //     },
  //     immediate:true
  //   },
  // },
  // beforeDestroy() {
  //   // 清除定时器
  //   clearInterval(this.timer);
  // },
};
</script>

<style lang="scss" scoped>


</style>
